/**
 * Just a simple utility to format dates in ICS format
 */

export class DateFormatter {

    static format(date: Date): string {
        const pad = DateFormatter.pad
        return (
            date.getFullYear().toString() +
            pad(date.getUTCMonth() + 1) +
            pad(date.getUTCDate()) +
            "T" +
            pad(date.getUTCHours()) +
            pad(date.getUTCMinutes()) +
            pad(date.getUTCSeconds()) +
            "Z"
        )
    }

    private static pad(num: number): string {
        if (num < 10) {
            return `0${num}`
        }
        return num.toString()
    }

    static renderDateForWikitext(date: Date): string {
        const pad = DateFormatter.pad
        return pad(date.getHours()) + ':' + pad(date.getMinutes())
    }

}
