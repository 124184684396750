import {AppBar, Box, Button, Toolbar, Typography} from "@mui/material"

export function TitleBar() {

  return <AppBar component="div" sx={{position: "relative"}}>
    <Toolbar>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
      >
        Scheduler
      </Typography>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Button
          href='https://gitlab.com/rileyvel/work-sprint-scheduler'
          target='_blank'
          sx={{color: 'white'}}
        >Repo</Button>
      </Box>
    </Toolbar>
  </AppBar>

}