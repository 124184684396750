import {Button, ButtonGroup, Typography} from "@mui/material"
import styled from "styled-components"
import {PresetManifest, presets} from "../../data/presets.ts"
import {DataStore} from "../../data/data-store.ts"

const PresetButtonGroupStyleContainer = styled.div`
    display: block;
    margin: auto;
    
    & > *:first-child {
        text-align: center;
        margin-bottom: 5px;
    }
`

export function PresetButtonGroup() {
  
  function applyPreset(preset: PresetManifest) {
    DataStore.patchFormData(preset.settings)
  }

  return <PresetButtonGroupStyleContainer>
    <Typography variant='body1'>Apply Preset</Typography>
    <ButtonGroup color="secondary">
      {presets.map((p => <Button
        key={p.uuid}
        onClick={() => applyPreset(p)}
      >
        {p.name}
      </Button>))}
    </ButtonGroup>
  </PresetButtonGroupStyleContainer>

}

