import {TitleBar} from "./components/title-bar.tsx"
import {Layout} from "./components/layout.tsx"
import {MainContent} from "./components/main-content.tsx"
import {Box, styled} from "@mui/material"

const MyBox = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
`

function App() {

  return <MyBox>
    <TitleBar />
    <Layout>
      <MainContent />
    </Layout>
  </MyBox>

}

export default App
