import {FormDataSchema, FormDataType} from "../../data/form-data.model.ts"

const KEY = 'saved-form-data'

export const DEFAULT_STATE = {
  eventName: 'Scheduled Work Session',
  startTime: new Date(),
  breakLength: 10,
  sessionLength: 40,
  remindersEnabled: true,
  numSessions: 3
}

class PersistenceImpl {

  public get(): FormDataType {

    const rawSavedDataString = localStorage.getItem(KEY)
    if (!rawSavedDataString) {
      return DEFAULT_STATE
    }

    const saveData = FormDataSchema.safeParse(JSON.parse(rawSavedDataString))
    if (!saveData.success) {
      return DEFAULT_STATE
    }

    return saveData.data

  }

  public set(formData: FormDataType): void {
    FormDataSchema.parse(formData)
    localStorage.setItem(KEY, JSON.stringify(formData))
  }

}

export const PersistenceService = new PersistenceImpl()
