import {useObservableEagerState} from "observable-hooks"
import {DataStore} from "../../data/data-store.ts"
import {Slider, Typography} from "@mui/material"

export function SessionCountSlider() {

  const activatedFormData = useObservableEagerState(DataStore.formData$)

  return <div>
    <Typography>Session Count: {activatedFormData.numSessions}</Typography>
    <Slider
      value={activatedFormData.numSessions}
      onChange={(_, value) => DataStore.patchFormData({numSessions: value as number})}
      marks={true}
      valueLabelDisplay='auto'
      step={1}
      min={1}
      max={5}
    />
  </div>

}
