import {WorkBlock} from "../scheduling/work-block/work-block.ts"

// OO Calendar Class
export class ICSFile {

    events: WorkBlock[] = []

    public toICSString(): string {
        const allEvents = this.events.map(item => item.getICSFragment()).join('\n')
        return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//RileyCal PreBeta//EN
CALSCALE:GREGORIAN
METHOD:PUBLISH
${allEvents}
END:VCALENDAR`
    }

    public addEvent(event: WorkBlock): void {
        this.events.push(event)
    }

}


