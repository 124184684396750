import {useObservableEagerState} from "observable-hooks"
import {DataStore} from "../../data/data-store.ts"
import {Slider, Typography} from "@mui/material"

export function BreakLengthSlider() {

  const activatedFormData = useObservableEagerState(DataStore.formData$)

  return <div>
    <Typography>Break Length: {activatedFormData.breakLength} minutes</Typography>
    <Slider
      value={activatedFormData.breakLength}
      onChange={(_, value) => DataStore.patchFormData({breakLength: value as number})}
      marks={true}
      valueLabelDisplay='auto'
      step={5}
      min={5}
      max={30}
    />
  </div>
}