import {useObservableEagerState} from "observable-hooks"
import {DataStore} from "../../data/data-store.ts"
import {TextField} from "@mui/material"

export function EventNameInput() {
  const activatedFormData = useObservableEagerState(DataStore.formData$)
  return <TextField
    label="Event Name"
    value={activatedFormData.eventName}
    onChange={e => DataStore.patchFormData({eventName: e.target.value})}
  />
}
