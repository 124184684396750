import {useObservableEagerState, useObservableState} from "observable-hooks"
import {DataStore} from "../../data/data-store.ts"
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {Button, Typography} from "@mui/material"
import dayjs, {Dayjs} from "dayjs"
import styled from "styled-components"
import relativeTime from "dayjs/plugin/relativeTime"
import {distinctUntilChanged, fromEvent, map, NEVER, Observable, startWith, switchMap, timer} from "rxjs"

dayjs.extend(relativeTime)

const FlowContainer = styled.div`
  
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  width: 100%;
  justify-content: space-around;
  
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  
  & > .grow {
    flex-grow: 1;
  }
  
  & > .no-grow {
    flex-grow: 0;
  }
  
`

export function StartDateTimePicker() {

  const activatedFormData = useObservableEagerState(DataStore.formData$)
  const dayjsStartTime = dayjs(activatedFormData.startTime)

  // This is a Dayjs instance that updates every 5 seconds.
  // Ensures that the start time text is always up-to-date,
  // but does not clog up the user's CPU if the page is not visible.
  const now = useObservableState(now$, dayjs())

  function minusFive() {
    DataStore.patchFormData({startTime: dayjsStartTime.subtract(5, 'minute').toDate()})
  }

  function plusFive() {
    DataStore.patchFormData({startTime: dayjsStartTime.add(5, 'minute').toDate()})
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Typography>Start time: {dayjsStartTime.format('LT')}, {dayjsStartTime.from(now)}</Typography>
    <FlowContainer>

      <DatePicker
        value={dayjsStartTime}
        onChange={value => value && DataStore.patchFormData({startTime: value.toDate()})}
        className='grow'
      />

      <TimePicker
        value={dayjsStartTime}
        onChange={value => value && DataStore.patchFormData({startTime: value.toDate()})}
        className='grow'
      ></TimePicker>

      <Button
        variant='outlined'
        onClick={minusFive}
        className='no-grow'
      >-5</Button>

      <Button
        variant='outlined'
        onClick={plusFive}
        className='no-grow'
      >+5</Button>

    </FlowContainer>
  </LocalizationProvider>

}

const pageVisibility$: Observable<boolean> = fromEvent(window, 'visibilitychange').pipe(
  startWith(void 0),
  map(() => document.visibilityState === 'visible'),
  distinctUntilChanged()
)

/**
 * Emits the current time as a Dayjs instance every 5 seconds,
 * while and only while the page is visible.
 */
const now$: Observable<Dayjs> = pageVisibility$.pipe(
  switchMap(visible => visible ? timer(0, 5000): NEVER),
  map(() => dayjs())
)
