import {AlarmBlock} from "./alarm-block.ts"
import {DateFormatter} from "./date-formatter.ts"
import {FormDataType} from "../../data/form-data.model.ts"

// Work session, one event.
export class WorkBlock {

  private uuid: string = crypto.randomUUID()
  private timeStamp = new Date()
  private alarmBlock : AlarmBlock

  constructor(public name: string,
              public startTime: Date,
              public endTime: Date,
              formData: FormDataType) {
    this.alarmBlock = new AlarmBlock(this, formData)
  }

  public getICSFragment(): string {
    return `BEGIN:VEVENT
SUMMARY:${this.name}
ORGANIZER:RileyCal
UID:${this.uuid}
STATUS:CONFIRMED
DTSTART:${DateFormatter.format(this.startTime)}
DTEND:${DateFormatter.format(this.endTime)}
DTSTAMP:${DateFormatter.format(this.timeStamp)}
SUMMARY:${this.name}${this.alarmBlock.getIcsBlock()}
END:VEVENT`
  }

  public getWikitextFragment(): string {
    const r = DateFormatter.renderDateForWikitext
    return `{{subst:SchedulerItem|${r(this.startTime)}|${r(this.endTime)}}}`
  }

  /**
   * Gets the duration in minutes.
   */
  get durationMin(): number {
    const startTimestamp = this.startTime.valueOf()
    const endTimestamp = this.endTime.valueOf()
    return (endTimestamp - startTimestamp) / (60 * 1000)
  }

}
