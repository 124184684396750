import {FormDataType} from "./form-data.model.ts"

export type PresetManifest = {
  uuid: string,
  name: string,
  settings: Partial<FormDataType>
}

export const presets = [
  {
    uuid: '777eddad-3023-4d09-8f64-d79910a87d70',
    name: 'Preset 1',
    settings: {
      breakLength: 10,
      sessionLength: 40,
      numSessions: 3
    }
  },
  {
    uuid: 'baae00ed-19ef-49da-acd7-3274f39e3b0d',
    name: 'Preset 2',
    settings: {
      breakLength: 5,
      sessionLength: 25,
      numSessions: 3
    }
  },
  {
    uuid: '93fe0e6b-db6e-4808-876f-b60ff5268640',
    name: 'Preset 3',
    settings: {
      breakLength: 10,
      sessionLength: 40,
      numSessions: 4
    }
  }
] satisfies PresetManifest[]