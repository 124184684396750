import {Button, ButtonGroup, Typography} from "@mui/material"
import {DataStore} from "../../data/data-store.ts"
import {useObservableEagerState} from "observable-hooks"
import dayjs from "dayjs"
import {useState} from "react"
import {WikitextDialogue} from "../services/wikitext-dialogue.tsx"
import {downloadIcsFile} from "../services/download-ics.ts"

export function ConfirmationServiceGroup() {

  const schedule = useObservableEagerState(DataStore.schedule$)
  const endTime = dayjs(schedule[schedule.length - 1].endTime)

  const [wikitextDialogueOpen, setWikitextDialogueOpen] = useState(false)

  function openDialogue() {
    setWikitextDialogueOpen(true)
  }

  function download() {
    downloadIcsFile(schedule)
  }

  function reset() {
    DataStore.reset()
  }

  return <>

    <Typography component='p'>Estimated end time: {endTime.format('LL LT')}</Typography>

    <ButtonGroup variant='contained' sx={{width: 'max-content', margin: 'auto'}}>
      <Button color='error' onClick={reset}>Reset</Button>
      <Button color='secondary' onClick={openDialogue}>Show Wikitext</Button>
      <Button color='success' onClick={download}>Download ICS</Button>
    </ButtonGroup>

    <WikitextDialogue open={wikitextDialogueOpen} onClose={() => setWikitextDialogueOpen(false)} />
  </>

}

