import {BehaviorSubject, map} from "rxjs"
import {FormDataType} from "./form-data.model.ts"
import {WorkSchedule} from "../scheduling/work-schedule.ts"
import {DEFAULT_STATE, PersistenceService} from "../components/persistence/persistence.ts"

class DataStoreImpl {

  public formData$ = new BehaviorSubject<FormDataType>(this.getInitialData())

  constructor() {
    this.formData$
      .subscribe(data => PersistenceService.set(data))
  }

  public setFormData(formData: FormDataType): void {
    this.formData$.next(formData)
  }

  public patchFormData(patch: Partial<FormDataType>): void {
    this.setFormData({...this.formData$.value, ...patch})
  }

  public schedule$ = this.formData$.pipe(
    map(formData => {
      const b = new WorkSchedule(formData)
      return b.generate()
    })
  )

  public wikitext$ = this.schedule$.pipe(
    map(workBlocks => workBlocks.map(item => item.getWikitextFragment()).join('\n'))
  )

  public reset(): void {
    const data = DEFAULT_STATE
    data.startTime = this.computeStartTime()
    this.setFormData(data)
  }

  private getInitialData(): FormDataType {
    const initialData = PersistenceService.get()
    return {
      ...initialData,
      startTime: this.computeStartTime()
    }
  }

  /**
   * Used on startup to get the default start time.
   * It should be the next 5th minute from now.
   * @private
   */
  private computeStartTime(): Date {
    const now = new Date()
    const minutes = now.getMinutes()
    const next5thMinute = Math.ceil(minutes / 5) * 5
    if (next5thMinute >= 60) { // value above 60 should be impossible, but just in case
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0)
    }
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), next5thMinute)
  }

}

export const DataStore = new DataStoreImpl()
