import {WorkBlock} from "./work-block/work-block.ts"
import {FormDataType} from "../data/form-data.model.ts"

export class ScheduleBuilder {

    sessions: WorkBlock[] = []
    private timeCursor: Date

    constructor(private formData: FormDataType) {
        this.timeCursor = formData.startTime
    }

    appendSession(lengthInMinutes: number) {

        const startTime = this.timeCursor
        const endTime = this.getCursorPlusMinutes(lengthInMinutes)

        const session = new WorkBlock(this.formData.eventName, startTime, endTime, this.formData)
        this.sessions.push(session)
        this.timeCursor = endTime

    }

    jumpAhead(nMinutes: number) {
        this.timeCursor = this.getCursorPlusMinutes(nMinutes)
    }

    private getCursorPlusMinutes(nMinutes: number): Date {
        const oldMS = this.timeCursor.valueOf()
        const msToAdd = nMinutes * 60 * 1000
        return new Date(oldMS + msToAdd)
    }

}