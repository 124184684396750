import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography} from "@mui/material"
import {useCallback, useEffect, useState} from "react"
import styled from "styled-components"
import {useObservableState} from "observable-hooks"
import {DataStore} from "../../data/data-store.ts"

export function WikitextDialogue(props: { open: boolean, onClose: () => void }) {

  return <Dialog
    open={props.open}
    onClose={props.onClose}
  >
    {props.open && <Content onClose={props.onClose} />}
  </Dialog>

}

const MyPre = styled.pre`
  min-width: min(400px, 80vw);
`

function Content(props: {onClose: () => void}) {

  const [hasCopied, setHasCopied] = useState(false)

  // hasCopied flash for 2 seconds, then revert to false
  useEffect(() => {
    if (hasCopied) {
      const timer = setTimeout(() => setHasCopied(false), 5000)
      return () => clearTimeout(timer)
    }
  }, [hasCopied])

  const wikitext = useObservableState(DataStore.wikitext$, '')

  const copy = useCallback(async () => {
    await navigator.clipboard.writeText(wikitext)
    setHasCopied(true)
  }, [wikitext])

  function done() {
    setHasCopied(false)
    props.onClose()
  }

  // Automatically copy on open
  useEffect(() => {
    if (wikitext) {
      void copy()
    }
    return () => setHasCopied(false)
  }, [wikitext, copy])

  return <>
    <DialogTitle>Wikitext</DialogTitle>
    <DialogContent>
      <Typography>Here is your generated Wikitext:</Typography>
      <MyPre>{wikitext}</MyPre>
    </DialogContent>
    <DialogActions>
      <Button onClick={copy}>Copy</Button>
      <Button onClick={done}>Done</Button>
    </DialogActions>

    <Snackbar open={hasCopied}>
      <Alert severity='success'>Copied to clipboard!</Alert>
    </Snackbar>
  </>

}
