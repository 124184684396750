import {useObservableEagerState} from "observable-hooks"
import {DataStore} from "../../data/data-store.ts"
import {Slider, Typography} from "@mui/material"

export function SessionLengthSlider() {

  const activatedFormData = useObservableEagerState(DataStore.formData$)

  return <div>
    <Typography>Session Length: {activatedFormData.sessionLength} minutes</Typography>
    <Slider
      value={activatedFormData.sessionLength}
      onChange={(_, value) => DataStore.patchFormData({sessionLength: value as number})}
      marks={true}
      valueLabelDisplay='auto'
      step={5}
      min={10}
      max={50}
    />
  </div>
}