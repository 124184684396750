import {z} from "zod"

export const FormDataSchema = z.object({
  eventName: z.string(),
  sessionLength: z.number(),
  breakLength: z.number(),
  startTime: z.coerce.date(),
  numSessions: z.number(),
  remindersEnabled: z.boolean()
})

export type FormDataType = z.infer<typeof FormDataSchema>;
