// The Calendar Generator class service
// Will be orchestrated by the form component.

import {ICSFile} from "./ics-file.ts"
import {WorkBlock} from "../scheduling/work-block/work-block.ts"

export class CalendarGenerator {

    static fromEvents(events: WorkBlock[], icsFile: ICSFile = new ICSFile()): string {
        events.forEach(item => icsFile.addEvent(item))
        return icsFile.toICSString()
    }

}

