import styled from 'styled-components'
import {Container} from "@mui/material"
import {SessionLengthSlider} from "./form-parts/session-length-slider.tsx"
import {BreakLengthSlider} from "./form-parts/break-length-slider.tsx"
import {StartDateTimePicker} from "./form-parts/start-date-time-picker.tsx"
import {SessionCountSlider} from "./form-parts/session-count-slider.tsx"
import {ConfirmationServiceGroup} from "./form-parts/confirmation-service-group.tsx"
import {ReminderSwitch} from "./form-parts/reminder-switch.tsx"
import {EventNameInput} from "./form-parts/event-name-input.tsx"
import {PresetButtonGroup} from "./form-parts/preset-button-group.tsx"

const MainFormDiv = styled.div`
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    gap: 20px;

    margin-top: 50px;
`

export function MainContent() {

  return <Container>
    <MainFormDiv>
      <PresetButtonGroup/>
      <EventNameInput/>
      <SessionLengthSlider/>
      <BreakLengthSlider/>
      <StartDateTimePicker/>
      <SessionCountSlider/>
      <ReminderSwitch/>
      <ConfirmationServiceGroup/>
    </MainFormDiv>
  </Container>

}

