// Work schedule, a collection of work sessions

import {WorkBlock} from "./work-block/work-block.ts"
import {ScheduleBuilder} from "./schedule-builder.ts"
import {FormDataType} from "../data/form-data.model.ts"

export class WorkSchedule {

    constructor(private data: FormDataType) {
    }

    /**
     * Generate all work sessions for this schedule
     */
    generate(): WorkBlock[] {

        const sb = new ScheduleBuilder(this.data)

        for (let i = 0; i < this.data.numSessions; ++i) {
            sb.appendSession(this.data.sessionLength)
            sb.jumpAhead(this.data.breakLength)
        }

        return sb.sessions

    }

}

