import {CalendarGenerator} from "../../ics/calendar-generator.ts"
import {WorkBlock} from "../../scheduling/work-block/work-block.ts"
import download from "downloadjs"

/**
 * Given a list of work blocks, generate an ICS containing them and download it.
 * @param workBlocks The work blocks to download.
 * @pure
 */
export function downloadIcsFile(workBlocks: WorkBlock[]) {
  const icsString = CalendarGenerator.fromEvents(workBlocks)
  download(icsString, `Work schedule ${new Date().toISOString()}.ics`, 'text/calendar')
}
