import {FormControlLabel, Switch} from "@mui/material"
import {DataStore} from "../../data/data-store.ts"
import {useObservableEagerState} from "observable-hooks"
import {ChangeEvent} from "react"
import styled from "styled-components"

const CentreContainer = styled.div`
  display: block;
  width: max-content;
  margin-inline: auto;
`

export function ReminderSwitch() {

  const activatedFormData = useObservableEagerState(DataStore.formData$)

  function handleChange({target: {checked}}: ChangeEvent<HTMLInputElement>) {
    DataStore.patchFormData({remindersEnabled: checked})
  }

  return <CentreContainer>
    <FormControlLabel
      control={<Switch
        checked={activatedFormData.remindersEnabled}
        onChange={handleChange}
      />}
      label="Set calendar reminders"
    />
  </CentreContainer>

}
